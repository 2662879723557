@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

*
{
    margin: 0;
    padding: 0;
}

*::selection
{
    /* color: #aaaaaa; */
    background: #ffffff22;
}

html,
body
{
    /* overflow: hidden; */
    background: #000;
    font-family: 'Raleway', sans-serif;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

button {
    display: none;
}

canvas{
    overflow : scroll;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
}

#menuToggle
{
  display: block;
  position: fixed;
  top: 50px;
  right: 50px;
  
  z-index: 20;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #6d6d6d;
  
  transition: all 0.3s ease;
}

#menuToggle a:hover
{
  color: rgb(0, 0, 0);
  font-weight: 700;
  text-decoration: underline;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}


#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #cdcdcd;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  position: absolute;
  width: 300px;
  height: 100vh;
  margin: -100px 0 0 -300px;
  padding: 50px;
  padding-top: 125px;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}

/* Progress bar */
#progress-bar {
	--scrollAmount: 0%;
	
	background-image: linear-gradient(120deg, #ffffff 0%, #ededed 100%);
	width: var(--scrollAmount);

		/* background: linear-gradient(to right, #ededed var(--scrollAmount), transparent 0);
	width: 100%; */
	
	height: 6px;
	position: fixed;
	top: 0;
    z-index: 10;

    box-shadow: 8px 6px 20px 2px #000000c4;
}

.title
{
    /* position: fixed; */
    position: absolute;
    top: calc(50% - 14vmin / 2);
    width: 100%;
    left: 0;
    color: #ffffff;
    text-align: center;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 6px 6px 10px rgba(0, 0, 0, 0.7);
}

.title-main
{
    font-size: 10vmin;
    text-transform: uppercase;
    font-weight: 800;

}

.title-description
{
    font-size: 2.2vmin;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.14em;
    margin-top: 1em;
    margin-left: 1.2rem;
}

.section-1 {
    color: white;
    min-height: 100vh;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
}

.arrow {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: bouncing 1s infinite ease-in-out;
}

.arrow a {
    text-decoration: none;
    color: #ffffffdd;
    width: 100%;
    padding: 2.5rem 0;
}

.arrow svg {
    width: 100%;
    height: 1.8rem;
}

@keyframes bouncing {
    0% {bottom: 0;}
    50% {bottom: 20px;}
    100% {bottom: 0;}
}

.section-2, .section-3,
.section-4, .section-5,
.section-6, .section-7,
.section-8  {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    z-index: 2;
    color: #ffffff;
}

.section-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 3rem;
}

.section-2 {
    /* background-color: #000; */
    min-height: 100vh;
}

.section-title {
    padding-top: 5rem;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0rem;
    border-bottom: 6px solid;
    width: 650px;
}

.about-card-1 {
    display: flex;
    background-color: #fff;
    padding: 2.5rem;
    border-radius: 20px;
    box-shadow: 8px 6px 20px 2px #000000c4;
    /* max-width: 560px; */
    margin-top: 3rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
}

.CV--ul {
    display: flex;
}

.CV--a {
    background-color: #fff;
    width: 16rem;
    padding: 1.3rem;
    border-radius: 10px;
    text-decoration: none;
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: 8px 6px 20px 2px #000000c4;
}

.CV--a-center {
    margin: 0 auto;
}

.CV--li {
    list-style: none;
    display: flex;
}

.CV--p {
    margin-left: 1rem;
    margin-top: 0.2rem;
}

.CV-svg {
    width: 2rem;
    height: 2rem;
    fill: #000;
}

.CV-contact {
    margin-bottom: 4rem;
}

.about-image {
    width: auto;
    max-height: 12rem;
    border-radius: 50%;

    margin-top: 5.5rem;
}

.about-description {
    margin-left: 3rem;
    margin-right: 2rem;
}

.about-description p {
    text-align: justify;
    font-size: 1rem;
    font-weight: 300;
    color: #000;
    line-height: 32px;
}

.about-description p:not(:last-child) {
    margin-bottom: 2rem;
}


.section-3 {
    /* background-color: green; */
    min-height: 100vh;
}

.skills {
    display: flex;
    flex-wrap: wrap;
}

.skills-items li {
    list-style: none;
    margin-bottom: 3rem;
    font-size: 1.7rem;
}


.section-4 {
    /* background-color: pink; */
    min-height: 100vh;
}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.portfolio-item {
    width: 19.5rem;
    margin-bottom: 2rem;
    background-color: #fff;
    box-shadow: 8px 6px 20px 2px #000000c4;
    border-radius: 10px;
    color: #000;
}

.portfolio-item img {
    width: 100%;
    object-fit: cover;
    object-position: 0px 0px;
    height: 14rem;
    border-radius: 10px 10px 0 0;
}

.portfolio-description {
    padding: 1rem;
    padding-bottom: 1.5rem;
}

.portfolio-link {
    background-color: rgb(205, 205, 205);
    padding: 0.5rem 1rem;
    color: #000000;
    text-decoration: none;
    border-radius: 5px;
    margin-right: 0.4rem;
    transition: all 0.2s ease-in-out;
}

.portfolio-text {
    margin-top: 0.5rem;
    margin-bottom: 1.6rem;
}

.portfolio-link:hover {
    color: white;
    background-color: rgb(0, 0, 0);
    box-shadow: 0 0 0.5rem #000;
}

.portfolio-technologies {
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;
    font-size: 0.9rem;
    margin-bottom: 2rem;
}

.portfolio-technologies-item {
    margin-right: 0.6rem;
}


.section-5 {
    /* background-color: blue; */
    min-height: 100vh;
}

.academic-items {
    display: block;
}

.academic-item {
    background-color: white;
    color: #000;
    padding: 1.5rem;
    border-radius: 10px;
    max-width: 28rem;
    box-shadow: 8px 6px 20px 2px #000000c4;

}

.academic-item-center {
    margin: 0 auto;
}

.academic-location {
    font-size: 1rem;
    margin: 0.8rem 0;
}

.academic-title {
    font-size: 1.3rem;
    margin-bottom: 0.7rem;
}

.academic-description {
    font-size: 0.7rem;
    list-style-type: disc;
}

.section-6 {
    /* background-color: aqua; */
    min-height: 100vh;
}

.professional-item {
    max-width: 30rem;
}

.section-7 {
    /* background-color: yellow; */
    min-height: 100vh;
}

.activities-description {
    font-size: 1.3rem;
    margin-top: 1.3rem;
}

.activities-blockchain--card {
 max-width: 28rem;
}
.activities-hackathon--card {
 max-width: 20rem;
}
.activities-pan--card {
 max-width: 12rem;
}

.section-8 {
    /* background-color: rgb(255, 0, 238); */
    min-height: 100vh;
}

.social-message {
    max-width: 100%;
}

.social-card {
    width: 1rem;
    height: 1rem;
}

.social-items {
    display: flex;
}

.social-items a {
    margin-top: 2.6rem;
    margin-right: 4.4rem;
}

.social-card {
    list-style: none;
}

.social-item {
    transform: scale(2.2);
}

.mailto {
    text-decoration: none;
}

.mailto-width {
    width: 38rem;
}

.social-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.social-description {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

@media (max-width: 900px)  {
    /* canvas {
        display: none;
    } */

    .about-card-1 {
        display: block;
        padding: 1.5rem;
    }

    .about-image {
        display: block;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    .about-description p {
        font-size: 1rem;
    }
}

@media (max-width:750px) {

    #menuToggle {
        top: 25px;
        right: 25px;
    }
    .section-title {
        font-size: 2rem;
        width: 100%;
    }

    .about-card-1 {
        padding: 0.5rem;
    }

    .section-container {
        padding: 0 1rem;
    }

    .about-image {
        margin-top: 1rem;
    }

    .about-description {
        margin: 1rem;
    }

    .CV--a-center {
        margin: 0;
    }

    .section-2, .section-3, .section-4,
    .section-5, .section-6, .section-7 {
        min-height: auto;
    }

    .skills-items li {
        margin-bottom: 1.2rem;
        font-size: 1.2rem;
    }

    .mailto-width {
        width: 100%;
    }

    .social-items {
        flex-wrap: wrap;
        width: 100%;
    }

    .title {
        height: 100%;
    }

    .scrollable {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
    }

    .title-description {
        margin-left: 0.4rem;
    }

}